<template>
<v-container fluid>
            <v-row dense align="center">
          <!-- Days input - Unlimited -->
          <v-col v-if="label" cols="12">
            <label><strong>{{label}}</strong></label>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="days"
              type="number"
              :label="$t('generic.lang_days')"
              min="0"
              :rules="[rules.positiveNumber]"
              outlined
              dense
              @input="updateTotalMinutes"
              :data-layout="KEYBOARD.KEYSETS.NUMERIC"
                @focus="showTouchKeyboard"
            >
              <template v-slot:append>
                <span class="text-caption grey--text">{{$t('generic.lang_days')}}</span>
              </template>
            </v-text-field>
          </v-col>
          
          <!-- Hours input - Max 23 -->
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="hours"
              type="number"
              :label="$t('generic.lang_hours')"
              min="0"
              max="23"
              :rules="[rules.numberRange(hours, 0, 23), rules.positiveNumber]"
              outlined
              dense
              @input="validateHours"
              :data-layout="KEYBOARD.KEYSETS.NUMERIC"
                @focus="showTouchKeyboard"
            >
              <template v-slot:append>
                <span class="text-caption grey--text">hrs</span>
              </template>
            </v-text-field>
          </v-col>
          
          <!-- Minutes input - Max 59 -->
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="minutes"
              type="number"
              :label="$t('generic.lang_minutes')"
              min="0"
              max="59"
              :rules="[rules.numberRange(minutes, 0, 59), rules.positiveNumber]"
              outlined
              dense
              @input="validateMinutes"
              :data-layout="KEYBOARD.KEYSETS.NUMERIC"
                @focus="showTouchKeyboard"
            >
              <template v-slot:append>
                <span class="text-caption grey--text">mins</span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        
        <v-row v-if="showConversion">
          <v-col cols="12">
            <v-alert type="info" dense outlined>
              Total: {{ totalMinutes }} minutes
            </v-alert>
          </v-col>
        </v-row>
        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard"
                          :accept="hideTouchKeyboard"
                          :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidth"/>
        </div>
        </v-container>
  </template>
  
  <script>
  import validation from "@/mixins/validation/validation";
  import mixin from "@/mixins/KeyboardMixIns";
  export default {
    name: 'PeriodInput',
    
    props: {
      // Initial value in minutes
      value: {
        type: [Number, String],
        default: 0
      },
      label: {
        type: String,
        default: ''
      },
      
      // Whether to show the total minutes conversion
      showConversion: {
        type: Boolean,
        default: false
      }
    },
    mixins: [validation, mixin],
    data() {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        internalUpdate: false
      }
    },
    
    computed: {
      totalMinutes() {
        const d = parseInt(this.days) || 0
        const h = parseInt(this.hours) || 0
        const m = parseInt(this.minutes) || 0
        
        return (d * 24 * 60) + (h * 60) + m
      }
    },
    
    watch: {
      // When the external value changes, update the component
      value: {
        handler(newValue) {
          if (!this.internalUpdate) {
            this.convertFromMinutes(Number(newValue))
          }
        },
        immediate: true
      },
      
      // When totalMinutes changes, emit the updated value
      totalMinutes(newValue) {
        this.internalUpdate = true
        this.$emit('input', Number(newValue))
        // Reset after emission to prevent loops
        setTimeout(() => {
          this.internalUpdate = false
        }, 0)
      }
    },
    
    methods: {
      // Convert total minutes to days, hours, minutes
      convertFromMinutes(totalMinutes) {
        const total = parseInt(totalMinutes) || 0
        
        const days = Math.floor(total / (24 * 60))
        const remainderAfterDays = total % (24 * 60)
        const hours = Math.floor(remainderAfterDays / 60)
        const minutes = remainderAfterDays % 60
        
        this.days = days
        this.hours = hours
        this.minutes = minutes
      },
      
      // Update the total minutes based on inputs
      updateTotalMinutes() {
        this.$emit('input', this.totalMinutes)
      },
      
      // Validate hours to ensure they're within range
      validateHours() {
        let h = parseInt(this.hours) || 0
        
        if (h < 0) h = 0
        if (h > 23) h = 23
        
        this.hours = h
        this.updateTotalMinutes()
      },
      
      // Validate minutes to ensure they're within range
      validateMinutes() {
        let m = parseInt(this.minutes) || 0
        
        if (m < 0) m = 0
        if (m > 59) m = 59
        
        this.minutes = m
        this.updateTotalMinutes()
      }
    }
  }
  </script>
  
  <style scoped>
  .period-input-card {
    box-shadow: none !important;
  }
  </style>