<template>
  <v-card :loading="loading">
    <b-tabs content-class="mt-3">
      <b-tab :title="'Fristen für Bestellungen'" class="active">
        <v-container class="pa-1">
          <v-form ref="form" lazy-validation v-model="validTime">
            <v-row no-gutters>
              <v-col class="pl-2 pr-2" cols="12">
                <PeriodInput v-model="order" :label="$t('generic.lang_orderDeadlineUntilTheNextDay')" />
              </v-col>
              <v-col class="pl-2 pr-2" cols="12">
                <PeriodInput v-model="cancel" :label="$t('generic.lang_cancellationPeriodUntilTheNextDay')" />
              </v-col>
              <!-- <v-col class="pl-2 pr-2" cols="12" lg="6" md="6" sm="6">
                <v-text-field v-model="order" :data-layout="KEYBOARD.KEYSETS.NORMAL" @keypress="isNumber($event,order)"
                              dense autocomplete="off"
                              :label="$t('generic.lang_orderDeadlineUntilTheNextDay')" outlined @focus="showTouchKeyboard"/>
              </v-col>


              <v-col class="pl-2 pr-2" cols="12" lg="6" md="6" sm="6">
                <v-text-field v-model="cancel" :data-layout="KEYBOARD.KEYSETS.NORMAL" autocomplete="off"
                              dense :label="$t('generic.lang_cancellationPeriodUntilTheNextDay')"
                              outlined @focus="showTouchKeyboard" @keypress="isNumber($event,cancel)"

                />
              </v-col> -->
            </v-row>
          </v-form>
          <v-row no-gutters>
            <v-col cols="12" align="right" >
              <v-btn :disabled="!validTime||loading" :loading="loading"  color="primary" elevation="0" @click="update">
                {{ $t('generic.lang_save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </b-tab>
      <b-tab :title="'Speisen Typen'" class="inactive">
        <v-container class="pa-0">
          <v-form ref="lunchType" v-model="valid">
            <v-row no-gutters justify="center">
              <v-col class="pl-2 pr-2"  md="4" sm="6">
                <v-text-field :label="$t('generic.lang_name')" outlined dense :rules="[v=>v&&!!v || $t('generic.lang_requiredField')]" v-model="name"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              @focus="showTouchKeyboard"
                ></v-text-field>
              </v-col>
              <v-col class="pl-2 pr-2"  md="5" sm="6">
                <fontawesome-picker  dense :field-label="'icon'" v-model="icon" type="meal" :rules="[v=>v&&!!v || $t('generic.lang_requiredField')]"/>
              </v-col>

              <v-col md="3" align="center" sm="5">

                <template v-if="editMode">
                  <v-btn fab color="primary" elevation="2" class="ma-0 mr-1" depressed small :disabled="!valid||loadingTable" :loading="loadingTable" @click="updateLunchType">
                    <v-icon>
                      mdi-content-save
                    </v-icon>
                  </v-btn>
                  <v-btn fab color="error" elevation="2" class="ma-0 ml-1" depressed small :disabled="loadingTable" :loading="loadingTable" @click="cancelEdit()">
                    <v-icon>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </template>

                <v-btn v-else fab color="primary" elevation="2" class="ma-0" depressed small :disabled="!valid||loadingTable" :loading="loadingTable" @click="addLunchType">
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </v-col>
              <v-divider class="ma-0"/>
            </v-row>
          </v-form>
          <v-card class="mt-2">
            <v-data-table :headers="dataTableHeader" :items="data" :loading="loading">
              <template v-slot:item.mealTypeIcon="{item}">
                <font-awesome-icon :icon="['fal',item.mealTypeIcon]"  />
              </template>
              <template v-slot:item.crudEdit="{item}" >
                <v-btn fab elevation="1" color="success" dark x-small @click="showEdit(item)">
                  <v-icon size="16px">
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <template v-slot:item.crud="{item}" >
                <v-btn fab elevation="1" color="error" dark x-small @click="deleteLunchType(item)">
                  <v-icon size="16px">
                    mdi-trash-can-outline
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-container>
      </b-tab>
      <b-tab :title="$t('erp.lang_menuShortName')" class="inactive">
        <v-container class="pa-0">
          <v-form ref="shortName" v-model="shortNameValid">
            <v-row no-gutters justify="center">
              <v-col class="pl-2 pr-2"  md="4" sm="6">
                <v-text-field :label="$t('generic.lang_name')" outlined dense :rules="[v=>v&&!!v || $t('generic.lang_requiredField')]" v-model="menu.name"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              @focus="showTouchKeyboard"
                ></v-text-field>
              </v-col>
              <v-col class="pl-2 pr-2"  md="5" sm="6">
                <swatches
                    inline v-model="menu.color"
                    background-color="transparent"
                ></swatches>
              </v-col>

              <v-col md="3" align="center" sm="5">

                <template v-if="shortNameEditMode">
                  <v-btn fab color="primary" elevation="2" class="ma-0 mr-1" depressed small :disabled="!shortNameValid||loadingShortNameTable" :loading="loadingShortNameTable" @click="updateShortName">
                    <v-icon>
                      mdi-content-save
                    </v-icon>
                  </v-btn>
                  <v-btn fab color="error" elevation="2" class="ma-0 ml-1" depressed small :disabled="loadingShortNameTable" :loading="loadingShortNameTable" @click="cancelEditShortName">
                    <v-icon>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </template>

                <v-btn v-else fab color="primary" elevation="2" class="ma-0" depressed small :disabled="!shortNameValid||loadingShortNameTable" :loading="loadingShortNameTable" @click="addShortName">
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </v-col>
              <v-divider class="ma-0"/>
            </v-row>
          </v-form>
          <v-card class="mt-2">
            <Datatable
                :api-endpoint="ENDPOINTS.DATATABLES.KANTINE.SHORTNAMES"
                :datatable-headers="shortNamesHeaders"
                no-excel-export
                @editEntry="showEditShortName"
                @deleteEntries="deleteEntries"
                @deleteEntry="deleteEntry"
                ref="shortNames"
                show-delete-buttons
                show-edit-buttons
                :permissionDelete="true"
                :permissionEdit="true"
            />
          </v-card>
        </v-container>
      </b-tab>
      <b-tab :title="$t('accounting.lang_classes')" class="active">
        <v-container class="pa-1">
          <class-settings></class-settings>
        </v-container>
      </b-tab>
    </b-tabs>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard"
                          :accept="hideTouchKeyboard"
                          :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidth"/>
    </div>
  </v-card>
</template>

<script>
import mixin from "@/mixins/KeyboardMixIns";
import swatches from "vue-swatches"
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import {fal} from '@fortawesome/pro-light-svg-icons';
import {fas} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import FontawesomePicker from "@/components/common/iconPicker";
import PeriodInput from "@/components/common/PeriodInput";
import Datatable from "@/components/datatable/Datatable";
import ClassSettings from "../wedgets/ClassSettings"
library.add(
    fal,fas
)


export default {
  name: "GlobalSettings",
  components:{
    Datatable,
    FontawesomePicker,
    "font-awesome-icon":FontAwesomeIcon,
    swatches,
    ClassSettings,
    PeriodInput
  },
  mixins: [mixin],
  data() {
    return {
      ENDPOINTS,
      loading: false,
      loadingTable:false,
      loadingShortNameTable:false,
      valid:false,
      validTime:false,
      shortNameValid:false,
      order:null,
      cancel:null,
      name:null,
      icon:null,
      uuid:null,
      editMode:false,
      shortNameEditMode:false,
      menu:{
        uuid:null,
        name:null,
        color:null,
      },
      data:[],// lunch types goes here
      dataTableHeader:[
        {
          text: this.$t('generic.lang_name'),
          value:'mealTypeName'
        },
        {
          text: this.$t('generic.lang_icon'),
          value: 'mealTypeIcon'
        },
        {
          text: '',
          value:'crud',
          align:'center',
          width:100
        },
        {
          text: '',
          value:'crudEdit',
          align:'center',
          width:100
        },
      ],
      rules: {
        timeRule: (v) => {
          return /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/.test(v) || this.$t('generic.lang_invalidTimeDuration')   
        }
      },
      shortNamesHeaders:[
        {
          text: 'uuid',
          value:'id',
          width:100,
          hide:true,
        },
        {
          text:this.$t('erp.lang_menuShortName'),
          value:'name'
        },
        {
          text: this.$t('erp.lang_warecreate_color'),
          value: 'color'
        },
      ],

    }
  },
  watch:{
  },
  mounted() {
    this.getData();
    this.getAllTypes();
  },
  methods: {
    getData() {
      this.loading = true;
      this.axios.post(ENDPOINTS.KANTINE.SETTINGS.GLOBALSETTINGS.GET, {
      }).then((res) => {
        this.loading = false;
        this.order = res.data.canteen_orderDeadline;
        this.cancel = res.data.canteen_voidDeadline;
        this.$refs.form.validate()
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      });
    },
    update() {
      this.loading = true;
      this.axios.post(ENDPOINTS.KANTINE.SETTINGS.GLOBALSETTINGS.UPDATE, {
        canteen_orderDeadline: this.order,
        canteen_voidDeadline: this.cancel
      }).then((res) => {
        this.lunchbeeActivated = res.data.success;
        this.loading = false;

      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      }).finally(() => {
        this.loading = false;
      })
    },
    isNumber(evt,model){
      let charCode = (evt.which) ? evt.which : evt.keyCode;

      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault();
      } else {
        if(charCode===13){
          evt.preventDefault()
        }
        return true;
      }
    },
    addLunchType(){

      if(!this.$refs.lunchType.validate()){
        return;
      }

      this.loadingTable=true;

      this.axios.post('create/canteen/mealType/',{
        mealTypeName:this.name,
        mealTypeIcon:this.icon,
      }).then(res=>{

        //SUCCESS

        if(res.data.status==="SUCCESS"){
          Events.$emit("showSnackbar", {
            color: "success",
            message: this.$t('generic.lang_addedSuccessfully')
          });
          this.$refs.lunchType.reset()
          this.getAllTypes();
        }else{
          Events.$emit("showSnackbar", {
            color: "error",
            message: res.data.msg||res.data.status
          });
        }
      }).catch(err=>{
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      }).finally(()=>{
        this.loadingTable=false;
      })

    },
    getAllTypes(){
      this.loadingTable=true;

      this.axios.post('get/canteen/mealTypes/',)
          .then(res=>{
            this.data=res.data;
      }).catch(err=>{
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      }).finally(()=>{
        this.loadingTable=false;
      })

    },
    showEdit(item){
      this.editMode=true
      this.name=item.mealTypeName
      this.icon=item.mealTypeIcon
      this.uuid=item.id
    },
    cancelEdit(){
      this.editMode=false
      this.$refs.lunchType.reset();
    },
    updateLunchType(){
      if(!this.$refs.lunchType.validate()){
        return;
      }

      this.loadingTable=true;

      this.axios.post('update/canteen/mealType/',{
        mealTypeUUID:this.uuid,
        mealTypeName:this.name,
        mealTypeIcon:this.icon,
      }).then(res=>{

        //SUCCESS

        if(res.data.status==="SUCCESS"){
          Events.$emit("showSnackbar", {
            color: "success",
            message: this.$t('generic.lang_updatedSuccessfully')
          });
          this.$refs.lunchType.reset()
          this.editMode=false;
          this.getAllShortNames();
        }else{
          Events.$emit("showSnackbar", {
            color: "error",
            message: res.data.msg||res.data.status
          });
        }
      }).catch(err=>{
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      }).finally(()=>{
        this.loadingTable=false;
      })
    },

    addShortName(){

      if(!this.$refs.shortName.validate()){
        return;
      }

      this.loadingShortNameTable=true;

      this.axios.post(ENDPOINTS.KANTINE.SETTINGS.GLOBALSETTINGS.MENUSHORTNAME.CREATE,{
        menuShortName:this.menu.name,
        menuShortNameColor:this.menu.color,
      }).then(res=>{

        //SUCCESS

        if(res.data.status==="SUCCESS"){
          Events.$emit("showSnackbar", {
            color: "success",
            message: this.$t('generic.lang_addedSuccessfully')
          });
          this.$refs.shortName.reset()
          this.$refs.shortNames.getDataFromApi();
        }else{
          Events.$emit("showSnackbar", {
            color: "error",
            message: res.data.msg||res.data.status
          });
        }
      }).catch(err=>{
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      }).finally(()=>{
        this.loadingShortNameTable=false;
      })

    },

    showEditShortName(item){
      this.shortNameEditMode=true
      this.menu.uuid=item.id
      this.menu.name=item.name
      this.menu.color=item.color

    },
    cancelEditShortName(){
      this.shortNameEditMode=false
      this.$refs.shortName.reset();
    },
    deleteLunchType(item){
      this.loadingTable=true;
      
      let uuids=[]
      uuids.push(item.uuid)

      this.axios.post('delete/canteen/mealType/',{
        mealTypeUUIDs:uuids
      }).then(res=>{
            if(res.data.status==='SUCCESS'){
              Events.$emit("showSnackbar", {
                color: "success",
                message: this.$t('generic.lang_deletedSuccessfully')
              });
              this.$refs.lunchType.reset()
              this.editMode=false;
              this.getAllTypes()
            }else{
              Events.$emit("showSnackbar", {
                color: "error",
                message: res.data.msg||res.data.status
              });
            }
      }).catch(err=>{
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      }).finally(()=>{
        this.loadingTable=false;
      })
    },
    updateShortName(){
      if(!this.$refs.shortName.validate()){
        return;
      }

      this.loadingShortNameTable=true;

      this.axios.post(ENDPOINTS.KANTINE.SETTINGS.GLOBALSETTINGS.MENUSHORTNAME.UPDATE,{
        menuShortNameUUID:this.menu.uuid,
        menuShortName:this.menu.name,
        menuShortNameColor:this.menu.color,
      }).then(res=>{

        //SUCCESS

        if(res.data.status==="SUCCESS"){
          Events.$emit("showSnackbar", {
            color: "success",
            message: this.$t('generic.lang_updatedSuccessfully')
          });
          this.$refs.shortName.reset()
          this.shortNameEditMode=false;
          this.$refs.shortNames.getDataFromApi();
        }else{
          Events.$emit("showSnackbar", {
            color: "error",
            message: res.data.msg||res.data.status,
          });
        }
      }).catch(err=>{
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      }).finally(()=>{
        this.loadingShortNameTable=false;
      })
    },
    deleteEntry(entry) {
      this.deleteShortName([entry.id])
    },
    deleteEntries(uuids) {
      this.deleteShortName(uuids);
    },
    deleteShortName(uuidsToDelete) {
      this.$swal({
        title: this.$t('erp.lang_deleteShortNameHeader'),
        text: this.$t('erp.lang_deleteShortNameBody'),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.axios.post(ENDPOINTS.KANTINE.SETTINGS.GLOBALSETTINGS.MENUSHORTNAME.DELETE, {
            menuShortNameUUIDs: uuidsToDelete,
          }).then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t('erp.lang_ActionSuccessful'),
                color: "success"
              });

              this.$refs.shortNames.getDataFromApi();
              this.$refs.shortNames.resetSelectedRows();
            } else {
              Events.$emit("showSnackbar", {
                color: "error",
                message: this.$t('generic.lang_anErrorOccurredPLSTryAgain')
              });
            }
          }).catch((err) => {
            Events.$emit("showSnackbar", {
              color: "error",
              message: err.message
            });
          })
        }
      });
    },
  }
}
</script>

<style scoped>

</style>